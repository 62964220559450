var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.dropdown)?_c('div',[(!_vm.displayNext && !_vm.allAnswered && !_vm.preview)?_c('v-sheet',{staticClass:"px-4 py-2 my-5",staticStyle:{"border-radius":"8px"},style:(("border : 1px solid " + _vm.wsBACKGROUND))},[_c('h5',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.wsATTENTION}},[_vm._v("mdi-information")]),_vm._v(" "+_vm._s(_vm.$t('NotAllQuestionsAnswered')))],1)]):_vm._e(),_c('v-sheet',{staticClass:"  d-flex",class:[{'justify-end' : !_vm.displayPrev}]},[(_vm.displayPrev)?_c('v-sheet',{staticClass:"d-flex align-center",class:[
        { 'pa-4' : !_vm.SM },
        { 'pa-2' : _vm.SM },
        {'wsRoundedLightLeft' : _vm.displayNext && !_vm.SM },
        {'wsRoundedSemi' : !_vm.displayNext && !_vm.SM },
        {'pointer' : _vm.displayPrev }
      ],style:(("border : 1px solid " + _vm.wsBACKGROUND)),attrs:{"width":"50%"},on:{"click":_vm.previous}},[_c('v-icon',{staticClass:"mr-5",attrs:{"color":_vm.displayPrev ? _vm.wsACCENT : 'grey'}},[_vm._v("mdi-arrow-left")]),_c('h4',{style:(("color : " + (_vm.displayPrev ? _vm.wsACCENT : 'grey')))},[_vm._v(" "+_vm._s(_vm.$t('GoBack')))])],1):_vm._e(),(!_vm.preview || (_vm.preview && _vm.displayNext))?_c('v-sheet',{staticClass:"d-flex justify-end align-center pointer",class:[
                { 'pointer' : _vm.displayNext || (!_vm.displayNext && _vm.allAnswered) },
                { 'pa-4' : !_vm.SM },
                { 'pa-2' : _vm.SM },
                {'wsRoundedLightRight' : !_vm.SM },
                {'wsRoundedSemi' : !_vm.displayPrev || _vm.SM },
                {'pointer' : _vm.displayNext }
            ],attrs:{"color":(_vm.displayNext || _vm.allAnswered) && !_vm.LOADING ? _vm.wsATTENTION : 'grey',"dark":"","width":"50%"},on:{"click":_vm.next}},[_c('div',[_c('h4',{staticClass:"text-right"},[_vm._v(" "+_vm._s(!_vm.displayNext ? _vm.$t('FinishTest') : _vm.$t('Next'))+" ")])]),(_vm.displayNext || _vm.allAnswered)?_c('v-icon',{staticClass:"ml-5"},[_vm._v("mdi-arrow-right")]):_vm._e()],1):_vm._e()],1)],1):_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({class:[{'mt-5' : _vm.SM}],attrs:{"width":_vm.SM ? '100%' : null}},'v-sheet',attrs,false),on),[(!_vm.allAnswered || _vm.SM)?_c('v-sheet',{staticClass:"wsRoundedLight d-flex justify-space-between py-2 px-2",style:(("border : 1px solid " + _vm.wsDARKLIGHT))},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-sheet',{class:[{pointer : _vm.displayPrev}],staticStyle:{"border-radius":"50%"},attrs:{"color":_vm.previousColor(hover)},on:{"click":function($event){$event.stopPropagation();return _vm.previous($event)}}},[_c('v-icon',{attrs:{"color":_vm.displayPrev ? _vm.wsDARK : _vm.wsDARKLIGHT}},[_vm._v("mdi-arrow-left")])],1)]}}],null,true)}),_c('h5',{staticClass:"d-flex align-center justify-center wsDARKER",staticStyle:{"width":"180px"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Question'))+" "+_vm._s(_vm.selectedQuestion + 1)+" / "+_vm._s(_vm.questions.length))]),_c('v-icon',{attrs:{"color":_vm.wsDARKER}},[_vm._v("mdi-menu-down")])],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-sheet',{class:[{pointer : _vm.displayNext}],staticStyle:{"border-radius":"50%"},attrs:{"color":_vm.nextColor(hover)},on:{"click":function($event){$event.stopPropagation();return _vm.next($event)}}},[_c('v-icon',{attrs:{"color":_vm.displayNext ? _vm.wsDARK : _vm.wsDARKLIGHT}},[_vm._v("mdi-arrow-right")])],1)]}}],null,true)})],1):_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.wsACCENT,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)],1)]}}])},[_c('v-sheet',{staticClass:"pa-2 d-flex  flex-wrap",attrs:{"max-width":"238"}},_vm._l((_vm.questions),function(question,i){return _c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],key:i + 'qNavigation',staticClass:"d-flex align-center justify-center pointer",staticStyle:{"margin":"2.5px"},style:(_vm.getNavigationStyle(question,i)),attrs:{"height":"32","width":"32"},on:{"click":function($event){_vm.selectedQuestion = i;}}},[_vm._v(" "+_vm._s(i + 1)+" ")])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }